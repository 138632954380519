import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Posts from "../components/posts"
import CoverBlog from "../components/cover-blog"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    {/* <h1>Hi from the Dons page</h1>
    <p>Welcome to page Dons</p>
    <Link to="/">Go back to the homepage</Link> */}
    <CoverBlog
      img="markus-winkler-q3QPw37J6Xs-unsplash.jpg"
      postTitle={"Contact"}
      offset="55%"
    ></CoverBlog>
    <section
      id="contact"
      style={{
        paddingTop: "2em",
        paddingBottom: "2em",

        // height: "calc(100vw * 9 /16)",
        // maxHeight: "1000px",
      }}
    >
      <div
        style={{
          display: "flex",
          // flexDirection: "row",
          alignItems: "flex-start",
          // height: "100%",
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
          // justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <div style={{ width: "560px" }}>
          {/* <h2 style={{ textAlign: "center" }}>Contact</h2> */}
          <p>
            Si vous désirez prendre contact avec moi, n'hésitez pas à le faire
            par courrier électronique à l'adresse :{" "}
            <a href="mailto:contact@sebastiengil.com">
              contact@sebastiengil.com
            </a>
            .
          </p>
          <p>
            Pour continuer la discussion, ça se passe
            sur le <Link to="https://bit.ly/3oG3qtU">canal Telegram</Link>.
          </p>
        </div>
        <Posts lastPosts />
      </div>
    </section>
  </Layout>
)

export default ContactPage
